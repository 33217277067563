import { UntaggedGameList } from '../pages/Minigame/List';

function AdminMinigameUntaggedRoute() {
  return <UntaggedGameList />;
}

export const Component = AdminMinigameUntaggedRoute;

export function clientLoader() {
  return null;
}
